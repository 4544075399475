import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable()
export class GlobalConfig {

}
export const ApiUrls = Object.freeze({
  LOGIN_API: environment.BASE_API_URL + 'Login/Authenticate',
  PAGEMASTER: environment.BASE_API_URL + 'PageMaster/GetAllPageAsync/',
  PAGEDETAIL: environment.BASE_API_URL + 'PageMaster/GetAllPageDetail/',
  GETALLUSERROLE: environment.BASE_API_URL + 'Role/GetAllUserRole',
  UPDATEUSERROLE: environment.BASE_API_URL + 'Role/UpdateUserRole',
  INSERTUSERROLE: environment.BASE_API_URL + 'Role/InsertUserRole',
  DELETEUSERROLE: environment.BASE_API_URL + 'Role/DeleteUserRole/',
  USERDOCUMENTTYPE: environment.BASE_API_URL + 'DocumentType/GetAllUserDocumentType',
  GETALLUSERDETAILS: environment.BASE_API_URL + 'User/GetAllUserDetails',
  GetAllPatientRegister: environment.BASE_API_URL + 'PatientRegister/GetAllPatientRegister',
  GetAllPatientRegisterCache: environment.BASE_API_URL + 'PatientRegister/GetAllPatientRegisterCache',
  GETALLPATIENTSQL: environment.BASE_API_URL + 'PatientRegister/GetAllPatientRegisterAsyncFromSql',
  GETALLPATIENTEXCELRECORD: environment.BASE_API_URL + 'PatientRegister/GetAllPatientRegisterForExcel',
  GETNPATIENTREGISTER: environment.BASE_API_URL + 'PatientRegister/GetNPatientRegister',
  UPDATEPATIENTREGISTER: environment.BASE_API_URL + 'PatientRegister/UpdatePatientRegister',
  GETPATIENTBYID: environment.BASE_API_URL + 'PatientRegister/GetPatientRegisterById?patientID=',
  INSERTPATIENTREGISTER: environment.BASE_API_URL + 'PatientRegister/InsertPatientRegister',
  GetAllActivePatientVisitType: environment.BASE_API_URL + 'PatientVisitType/GetAllActivePatientVisitType',
  GETALLPATIENTVISITTYPE: environment.BASE_API_URL + 'PatientVisitType/GetAllPatientVisitType',
  UPDATEPATIENTVISITTYPE: environment.BASE_API_URL + 'PatientVisitType/UpdatePatientVisitType',
  INSERTPATIENTVISITTYPE: environment.BASE_API_URL + 'PatientVisitType/InsertPatientVisitType',
  DELETEPATIENTVISITTYPE: environment.BASE_API_URL + 'PatientVisitType/DeletePatientVisitType/',
  GETALLPATIENTFORMTYPE: environment.BASE_API_URL + 'PatientFormType/GetAllPatientFormType',
  UPDATEPATIENTFORMTYPE: environment.BASE_API_URL + 'PatientFormType/UpdatePatientFormType',
  INSERTPATIENTFORMTYPE: environment.BASE_API_URL + 'PatientFormType/InsertPatientFormType',
  DELETEPATIENTFORMTYPE: environment.BASE_API_URL + 'PatientFormType/DeletePatientFormType/',
  GETALLPATIENTALLERGYTYPE: environment.BASE_API_URL + 'PatientAllergyType/GetAllPatientAllergyType',
  UPDATEPATIENTALLERGYTYPE: environment.BASE_API_URL + 'PatientAllergyType/UpdatePatientAllergyType',
  INSERTPATIENTALLERGYTYPE: environment.BASE_API_URL + 'PatientAllergyType/InsertPatientAllergyType',
  DELETEPATIENTALLERGYTYPE: environment.BASE_API_URL + 'PatientAllergyType/DeletePatientAllergyType/',
  GETALLPATIENTALLERGY: environment.BASE_API_URL + 'PatientAllergy/GetAllPatientAllergy',
  UPDATEPATIENTALLERGY: environment.BASE_API_URL + 'PatientAllergy/UpdatePatientAllergy',
  INSERTPATIENTALLERGY: environment.BASE_API_URL + 'PatientAllergy/InsertPatientAllergy',
  DELETEPATIENTALLERGY: environment.BASE_API_URL + 'PatientAllergy/DeletePatientAllergy/',
  GETALLACTIVEPATIENTALLERGYTYPE: environment.BASE_API_URL + 'PatientAllergyType/GetAllActivePatientAllergyType',
  GETALLPATIENTQUESTION: environment.BASE_API_URL + 'PatientQuestion/GetAllPatientQuestionAsync',
  GETALLPATIENTQUESTIONACTIONCOMPLETE: environment.BASE_API_URL + 'PatientQuestion/GetAllPatientQuestionIsActionCompleteAsync',
  UPDATEPATIENTQUESTION: environment.BASE_API_URL + 'PatientQuestion/UpdatePatientQuestion',
  INSERTPATIENTQUESTION: environment.BASE_API_URL + 'PatientQuestion/InsertPatientQuestion',
  DELETEPATIENTQUESTION: environment.BASE_API_URL + 'PatientQuestion/DeletePatientQuestion/',
  INSERTPATIENTLABORATORY: environment.BASE_API_URL + 'PatientLaboratory/InsertPatientLaboratory',
  UPDATEPATIENTLABORATORY: environment.BASE_API_URL + 'PatientLaboratory/UpdatePatientLaboratory',
  DELETEPATIENTLABORATORY: environment.BASE_API_URL + 'PatientLaboratory/DeletePatientLaboratory/',
  GETALLPATIENTLABORATORY: environment.BASE_API_URL + 'PatientLaboratory/GetAllPatientLaboratory',
  GETALLVENDOTLIST: environment.BASE_API_URL + 'VendorDetail/GetAllVendorDetail/',
  INSERTVENDOR: environment.BASE_API_URL + 'VendorDetail/InsertVendorDetail/',
  UPDATEVENDOR: environment.BASE_API_URL + 'VendorDetail/UpdateVendorDetail/',
  DELETEVENDOR: environment.BASE_API_URL + 'VendorDetail/DELETEVENDOR/',
  INSERTPRODUCTINFORMATION: environment.BASE_API_URL + 'ProductInformation/InsertProductInformation',
  UPDATEPRODUCTINFORMATION: environment.BASE_API_URL + 'ProductInformation/UpdateProductInformation',
  DELETEPRODUCTINFORMATION: environment.BASE_API_URL + 'ProductInformation/DeleteProductInformation/',
  GETALLPRODUCTINFORMATION: environment.BASE_API_URL + 'ProductInformation/GetAllProductInformation',
  UPDATEPATIENTPRESCRIPTION: environment.BASE_API_URL + 'PatientPrescription/UpdatePatientPrescription',
  DELETEPATIENTPRESCRIPTION: environment.BASE_API_URL + 'PatientPrescription/DeletePatientPrescription/',
  GETALLACTIVEPRODUCTTYPE: environment.BASE_API_URL + 'ProductType/GetAllActiveProductType',
  GETALLACTIVEUOMTYPE: environment.BASE_API_URL + 'UOMType/GetAllActiveUOMType',
  GETALLACIVEVENDOR: environment.BASE_API_URL + 'VendorDetail/GetAllActiveVendorDetail',
  GETALLPATIENTINGREDIENT: environment.BASE_API_URL + 'PatientTherapieIngredient/GetAllPatientTherapieIngredient',
  UPDATEPATIENTINGREDIENT: environment.BASE_API_URL + 'PatientTherapieIngredient/UpdatePatientTherapieIngredient',
  INSERTPATIENTINGREDIENT: environment.BASE_API_URL + 'PatientTherapieIngredient/InsertPatientTherapieIngredient',
  GETALLACTIVEPRODUCTINFORMATION: environment.BASE_API_URL + 'ProductInformation/GetAllActiveProductInformation',
  INSERTPATIENTPRESCRIPTION: environment.BASE_API_URL + 'PatientPrescription/InsertPatientPrescription',
  GETALLPATIENTPRESCRIPTION: environment.BASE_API_URL + 'PatientPrescription/GetAllPatientPrescription',
  GETPRODUCTINFORMATIONBYID: environment.BASE_API_URL + 'ProductInformation/GetProductInformationById/',
  GETALLDOSElIST: environment.BASE_API_URL + 'PrescriptionDoseCalculation/GetAllPrescriptionDoseCalculation/',
  GETALLPRESCRIPTIONTYPE: environment.BASE_API_URL + 'PatientPrescription/GetAllPrescriptionType/',
  INSERTPRECALCULATION: environment.BASE_API_URL + 'PrescriptionDoseCalculation/InsertPrescriptionDoseCalculation/',
  UPDATEPRECALCULATION: environment.BASE_API_URL + 'PrescriptionDoseCalculation/UpdatePrescriptionDoseCalculation/',
  DELETEPRECALCULATIONBYID: environment.BASE_API_URL + 'PrescriptionDoseCalculation/DeletePrescriptionDoseCalculation/',
  GETALLACTIVEPRODUCT: environment.BASE_API_URL + 'ProductInformation/GetAllActiveProductInformation/',
  GETALLRXLOG: environment.BASE_API_URL + 'PatientPrescription/GetAllRxLog/',
  GETALLRXLOGCACHE: environment.BASE_API_URL + 'PatientPrescription/GetAllRxLogCache',
  GETALLRXLOGNEW: environment.BASE_API_URL + 'PatientPrescription/GetAllRxLogData',
  GETRXSUB: environment.BASE_API_URL + 'ProductInformation/GetProductInformationById/',
  GETOFFICELOG: environment.BASE_API_URL + 'SalesTax/GetAllOfficeSalesTaxAsync/',
  GETDOTERRALOG: environment.BASE_API_URL + 'SalesTax/GetAllDoTerraSalesTaxAsync/',
  UPDATEOFFICELOG: environment.BASE_API_URL + 'SalesTax/UpdateSalesTax/',
  INSERTOFFICELOG: environment.BASE_API_URL + 'SalesTax/InsertSalesTax/',
  GETPATIENTMONEYASYNC: environment.BASE_API_URL + 'PatientMoneyOwedLog/GetAllPatientMoneyOwedLogAsync/',
  INSERTPATIENTMONEYASYNC: environment.BASE_API_URL + 'PatientMoneyOwedLog/InsertPatientMoneyOwedLog/',
  UPDATEPATIENTMONEYASYNC: environment.BASE_API_URL + 'PatientMoneyOwedLog/UpdatePatientMoneyOwedLog/',
    GETTESTRONELOG: environment.BASE_API_URL + 'PatientPrescription/GetTestroneLog/',
    GETTESTRONELOGCACHE: environment.BASE_API_URL + 'PatientPrescription/GetTestroneLogCache/',
    INSERTPRESCRIPTIONHISTORY: environment.BASE_API_URL + 'PatientPrescription/InsertPrescriptionHistory',
    GETALLPATIENTALLERGYBYPATIENTID: environment.BASE_API_URL + 'PatientAllergy/GetAllPatientAllergyByPatientID?patientID=',
    GETALLPATIENTLABORATORYBYPATIENTID: environment.BASE_API_URL+ 'PatientLaboratory/GetAllPatientLaboratoryByPatientID?PatientID=',
    GETALLPATIENTQUESTIONBYPATIENTID: environment.BASE_API_URL + 'PatientQuestion/GetAllPatientQuestionByPatientID?patientID=',
    GETALLPATIENTTHERAPIES: environment.BASE_API_URL + 'PatientTherapieIngredient/GetAllPatientTherapieIngredient/',
    UPDATEPATIENTTHERAPIES: environment.BASE_API_URL + 'PatientTherapieIngredient/UpdatePatientTherapieIngredient',
    INSERTPATIENTTHERAPIES: environment.BASE_API_URL + 'PatientTherapieIngredient/InsertPatientTherapieIngredient',
    UPDATEUSER: environment.BASE_API_URL + 'User/UpdateUser',
    INSERTUSER:  environment.BASE_API_URL + 'User/InsertUser',
    ROLEPERMISSIONS: environment.BASE_API_URL + 'PageMaster/GetAllPagePermission/',
    GETALLPATIENTAPPOINTMENTSREPORT:environment.BASE_API_URL+'PatientAppointment/GetAllPatientAppointmentsReport',
    GETALLPATIENTAPPOINTMENTDUEREPORT: environment.BASE_API_URL + 'PatientRegister/GetAllPatientRegisterAppointmentDueReport',
    GETALLPATIENTIVCONSENTMISSINGREPORT: environment.BASE_API_URL + 'PatientRegister/GetAllPatientRegisterIVConsentMissingReport',
    GETALLPATIENTEDTACONSENTMISSINGREPORT:environment.BASE_API_URL+ 'PatientRegister/GetAllPatientRegisterEDTAConsentMissingReport',
    GETALLPATIENTMENSCONSENTMISSINGREPORT:environment.BASE_API_URL+ 'PatientRegister/GetAllPatientRegisterMensTestosteroneConsentMissingReport',
    GETALLPATIENTWOMENSCONSENTMISSINGREPORT:environment.BASE_API_URL+ 'PatientRegister/GetAllPatientRegisterWomensEstradiolBiestConsentMissingReport',
    GETALLPATIENTMEDICAREMISSINGREPORT:environment.BASE_API_URL+'PatientRegister/GetAllPatientRegisterMediCareMissingReport',
    GETPATIENTAPPOINTMENTBYID: environment.BASE_API_URL + 'PatientAppointment/GetAllPatientAppointmentByPatientID?patientID=',
    GEUSERDETAILBYUSERID: environment.BASE_API_URL + 'User/GetAllUserDetailByID?userID=',
    DeleteUsrUser: environment.BASE_API_URL + 'User/DeleteUsrUser/',
  INSERTPATIENTAPPOINTMENT: environment.BASE_API_URL + 'PatientAppointment/InsertPatientAppointment',
  UPDATEPATIENTAPPOINTMENT: environment.BASE_API_URL + 'PatientAppointment/UpdatePatientAppointment',
  DELETEPATIENTAPPOINTMENT: environment.BASE_API_URL + 'PatientAppointment/DeletePatientAppointment/',
  GETALLACTIVEAPPOINTMENTSTATUS: environment.BASE_API_URL + 'PatientAppointmentStatus/GetAllActiveAppointmentStatus',
  GETALLACTIVEPATIENTREGISTER: environment.BASE_API_URL + 'PatientRegister/GetAllActivePatientRegister',

    GETALLNONLIFETHREATENINGALLEGY:environment.BASE_API_URL+'PatientAllergyType/GetAllNonLifeThreateningAllergy',
    GETALLLIFETHREATENINGALLEGY:environment.BASE_API_URL+'PatientAllergyType/GetAllLifeThreateningAllergy',
    PRESCRIPTIONCLACBYID: environment.BASE_API_URL + 'PrescriptionDoseCalculation/GetPrescriptionDoseCalculationByProductId',
    GETALLDONOTSCHEDULELIST: environment.BASE_API_URL + 'PatientAppointment/GetAllDoNotList',
    UPDATEPATIENTDONOTSCHEDULE:environment.BASE_API_URL  + 'PatientRegister/UpdatePatientRegisterManageDonotSchedule',
    INSERTSUPPLEMENT:environment.BASE_API_URL+'PatientSupplement/InsertPatientSupplement',
    UPDATESUPPLEMENT:environment.BASE_API_URL+'PatientSupplement/UpdatePatientSupplement',
    GETALLSUPPLEMENTBYPATIENTID:environment.BASE_API_URL+'PatientSupplement/GetPatientSupplementByPatientID?patientID=',
    GETALLACTIVEPATIENTAPPOINTMENTS: environment.BASE_API_URL + 'PatientAppointment/GetAllActivePatientAppointments',
    GETALLPATIENTAPPOINTMENTS: environment.BASE_API_URL + 'PatientAppointment/GetAllPatientAppointments',
    InsertUpdateRolePermission: environment.BASE_API_URL + 'PageMaster/InsertUpdateRolePermission/',
    GETALLACTIVESUPPLEMENTS:environment.BASE_API_URL+'ProductSupplements/GetAllActiveProductSupplements',
    INSERTPRODUCTSUPPLEMENTS:environment.BASE_API_URL+'ProductSupplements/InsertProductSupplements',
    UPDATEPRODUCTSUPPLEMENTS:environment.BASE_API_URL+'ProductSupplements/UpdateProductSupplements',
    DELETEPRODUCTSUPPLEMENTS:environment.BASE_API_URL+'ProductSupplements/DeleteProductSupplements/',
    GETALLPRODUCTSUPPLEMENTS:environment.BASE_API_URL+'ProductSupplements/GetAllProductSupplements',
    INSERTPRESCRIPTIONS:environment.BASE_API_URL+'Prescriptions/InsertPrescriptions',
    UPDATEPRESCRIPTIONS:environment.BASE_API_URL+'Prescriptions/UpdatePrescriptions',
    DELETEPRESCRIPTIONS:environment.BASE_API_URL+'Prescriptions/DeletePrescriptions/',
    GETALLACTIVEPRESCRIPTIONS:environment.BASE_API_URL+'Prescriptions/GetAllActivePrescriptions',
    GETALLPRESCRIPTIONS:environment.BASE_API_URL+'Prescriptions/GetAllPrescriptions',
    GETALLACTIVEINGREDIENTLOTNUMBER:environment.BASE_API_URL+'ProductInformation/GetAllIngredientLotNumber/',
    GETPRESCRIPTIONDOSECALCULATIONBYPRESCRIPTIONBYID:environment.BASE_API_URL+'PrescriptionDoseCalculation/GetPrescriptionDoseCalculationByPrescriptionID/',
    GETALLPATIENTPRESCRIPTIONBYPATIENTID: environment.BASE_API_URL + 'PatientPrescription/GetAllPatientPrescriptionByPatientID/',
    GETALLPATIENTPRESCRIPTIONFROMSQLBYPATIENTID: environment.BASE_API_URL + 'PatientPrescription/GetRXLogViewModelAsyncFromSqlByPatientID/',
    GETALLDOSECALCULATIONLIST: environment.BASE_API_URL + 'PrescriptionDoseCalculation/GetAllPrescriptionDoseCalculation/',
    GETPATIENTBASIC: environment.BASE_API_URL + 'PatientRegister/GetPatientRegisterNameDetails/',
    GETTESTOSTERONESUB: environment.BASE_API_URL + 'PrescriptionDoseCalculation/GetPrescriptionDoseCalculationByPrescriptionID/',
    SETCACHERESET: environment.BASE_API_URL + 'PatientPrescription/SetRxLogTestosteroneCache/',
    GETRXFROMSQL: environment.BASE_API_URL + 'PatientPrescription/GetRXLogViewModelAsyncFromSql',
    GETTESTOSTEORNESQL: environment.BASE_API_URL + 'PatientPrescription/GetTestroneLogFromSql',
    GETUSERVIEWBYLOGINID: environment.BASE_API_URL + 'UserView/GetUserViewByLoginId',
    GETACTIVEUSERVIEW: environment.BASE_API_URL + 'UserView/GetActiveUserView',
    INSERTUSERVIEW: environment.BASE_API_URL + 'UserView/InsertUserView',
    UPDATEUSERVIEW: environment.BASE_API_URL + 'UserView/UpdateUserView',
    DELETEPATIENTSUPPLEMENT:environment.BASE_API_URL+'PatientSupplement/DeletePatientSupplement/',
    GETPATIENTTREATMENTPLANBYSUPPLEMENTID:environment.BASE_API_URL+'PatientTreatmentPlan/GetPatienTreatmentPlanBySupplmentId/',
    INSERTPATIENTTREATMENTPLAN:environment.BASE_API_URL+'PatientTreatmentPlan/InsertPatientTreatmentPlan',
    UPDATEPATIENTTREATMENTPLAN:environment.BASE_API_URL+'PatientTreatmentPlan/UpdatePatientTreatmentPlan',
    DELETEPATIENTTREATMENTPLAN:environment.BASE_API_URL+'PatientTreatmentPlan/DeletePatientTreatmentPlan/',
    GETPATIENTNAMEBYPATIENTID: environment.BASE_API_URL + 'PatientRegister/GetPatientRegisterNameByPatientID/',
    GetAllAllergys: environment.BASE_API_URL + 'PatientAllergyType/GetAllAllergys',
    GETALLLASTDONOTSEHEDULELISTAPPOINTMENTS:environment.BASE_API_URL+'PatientAppointment/GetAllLastDonotSchedulePatientAppointments',
    IVLOGGETBYYEAR: environment.BASE_API_URL + 'PatientTherapieIngredient/GetAllPatientTherapieIngredientByYear',
    OFFICELOGGETBYYEAR: environment.BASE_API_URL + 'SalesTax/GetAllOfficeSalesTaxByYearAsync',
    DOTERRALOGGETBYYEAR: environment.BASE_API_URL + 'SalesTax/GetAllDoTerraSalesTaxByYearAsync',
    PATIENTMONEYLOGGETBYYEAR: environment.BASE_API_URL + 'PatientMoneyOwedLog/GetAllPatientMoneyOwedLogByYearAsync',
});
