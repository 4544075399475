import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services';
import { environment } from 'src/environments/environment';
import { MockBackEndCalls } from './mock-backend';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private mockCalls: MockBackEndCalls) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.isAPIMock) {
            const retuValue = (this.mockCalls.mock(request.url, request.method, request));
            if (retuValue === null) {
                return next.handle(request);
            } else {
                return retuValue;
            }
        } else {
            return next.handle(request).pipe(catchError(err => {
                if (err.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authenticationService.logout();
                    location.reload(true);
                }
                if (err.status === 0 || err.status === 500) {
                    alert('Try again later..!');
                    this.authenticationService.logout();
                    location.reload(true);
                }
                const error = err.error.message || err.statusText;
                return throwError(error);
            }))
        }
    }
}
