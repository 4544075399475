import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
    private router: Router, private authenticationService: AuthenticationService,public toster:ToastrService) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get loginFormControl() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    let regData = {
      loginName: this.loginFormControl.username.value,
      passWord: this.loginFormControl.password.value,
      grantType: 'password'
    }
    this.loading = true;
    this.authenticationService.login(regData).subscribe(
      data => {
        
        if (data.message === 'OK') {
          if (data.data != null) {
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.authenticationService.setCurrentUser(data)
            this.router.navigate([this.returnUrl]);
          }
        }
        else {
          
          this.toster.error('Invalid Username or Password');
          this.error = 'Invalid Username or Password';
          this.loading = false;
          setTimeout(() => {
            this.error = '';
          }, 2000);
        }
      },
      error => {
        this.loading = false;
      });
  }
}
