export class User {
    message: string;
    data: UserData;
}

export class UserData {
    access_token?: string;
    expires_in: string;
    refresh_token: string;
    loginID: string;
    id: string;
    roleID: string;
    loginRole: string;
    loginName: string;
    userName: string;
    userID: string;
}