export class UserMenuList {
    auditTransactionType: string;
    contentMenuID: string;
    cssClass: string;
    description: string;
    gblPageMasterAudit: GblPageMasterAudit[];
    isActive: boolean;
    isParent: boolean;
    level: number;
    loginID: number;
    pageID: number;
    pageName: string;
    pageURL: string;
    parentID: string;
    processingDate: string;
    sequenceNumber: number;
    userRolePrivilege: UserRolePrivilege[];
}

export class GblPageMasterAudit {

}

export class UserRolePrivilege {
    auditTransactionType: string;
    gblPageMaster: string;
    isActive: boolean;
    isDelete: boolean;
    isEdit: boolean;
    isView: boolean;
    loginID: number;
    pageID: number;
    processingDate: string;
    roleID: number;
    rolePrivilegeID: number;
    userRolePrivilegeAudits: UserRolePrivilegeAudits[]
}
export class UserRolePrivilegeAudits {

}