export class AllPatientTherapieIngredient{
    patientTherapieIngredient: PatientTherapieIngredient[];
    patientTherapieShortIngredient: PatientTherapieShortIngredient[];
}
export class PatientTherapieIngredient{
    therapieIngredientID: number;
    patientID: number;
    edtaReceivedDate: Date;
    mgSO4ReceivedDate: Date;
    naHCO3ReceivedDate: Date;
    vitaminCReceivedDate: Date;
    b6ReceivedDate: Date;
    b12ReceivedDate: Date;
    glutathioneReceivedDate: Date;
    edtaFormDate: Date;
    mgSO4FormDate: Date;
    naHCO3FormDate: Date;
    vitaminCFormDate: Date;
    b6FormDate: Date;
    b12FormDate: Date;
    glutathioneFormDate: Date;
    patientTherapieIngredientAudits: Date;
    loginID: number;
    processingDate: Date;
    isActive: boolean;
    isConfirmDeleted: boolean;
    auditTransactionType: string;
}

export class PatientTherapieShortIngredient{
    therapieShotIngredientID: number;
    patientID: number;
    b12: boolean;
    magnesium: boolean;
    b12MagnesiumBComplex: boolean;
    patientTherapieShotIngredientAudits: Date;
    loginID: number;
    processingDate: Date;
    isActive: boolean;
    isConfirmDeleted: boolean;
    auditTransactionType: string;
}