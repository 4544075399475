import { Injectable } from '@angular/core';
import { HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUrls } from '../global-config';
import { userDataResponse } from '../models';

@Injectable()
export class MockBackEndCalls {
  mock(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (url.match(ApiUrls.LOGIN_API) && method === 'POST') {
      const responseBody = userDataResponse;
      return new Observable(resp => {
        resp.next(new HttpResponse({
          status: 200,
          body: Object.assign({}, responseBody)
        }));
        resp.complete();
      });
    }
    return null;
  }
}
