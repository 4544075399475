export class UserDocumentType{
    documentTypeID: number;
    documentType: string;
    userDocumentTypeAudits: UserDocumentTypeAudits[];
    loginID: number;
    processingDate: string;
    isActive: boolean;
    isConfirmDeleted: boolean;
    auditTransactionType: string
}
export class UserDocumentTypeAudits{

}