import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment'
import { User } from '../models';
import { Router } from '@angular/router';
import { ApiUrls } from '../global-config';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    setCurrentUser(data: any) {
        this.currentUserSubject.next(data);
    }

    login(loginData) {
        return this.http.post<any>(ApiUrls.LOGIN_API, loginData, {});
    }

    logout() {
        // remove user from local storage to log user out
        this.router.navigate(['login']);
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}