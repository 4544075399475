export const userDataResponse = {
    "message": "OK",
    "data": {
        "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJOYW1lIjoic2QiLCJSb2xlSUQiOiIxIiwiTG9naW5JRCI6IjEzIiwibmJmIjoxNTc0MDc2NjM0LCJleHAiOjE1NzQwODAyMzQsImlzcyI6Ik1hbmFnZXIiLCJhdWQiOiJNYW5hZ2VyIn0.78S6B0FlxOTRGvru1aaN7Fmopxs65L9_3tZAAETZROk",
        "expires_in": "2019-11-18T12:30:34Z",
        "refresh_token": "a42f914c-c085-4c2b-be5d-bfb190b4a6a2",
        "loginID": "13",
        "id": "13",
        "roleID": "1",
        "loginName": "sd",
        "userName": "sd",
        "userID": "12"
    }
}